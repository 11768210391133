<template>
  <div class="p-switch">
    <div v-if="$slots.prepend" class="p-switch__prepend">
      <slot name="prepend" />
    </div>
    <label :class="['p-switch__inner', `p-switch--${size}`]">
      <input
        :id="name"
        v-model="value"
        v-bind="{ ...$attrs, ...(inputAttributes as InputHTMLAttributes) }"
        :data-test="testAttribute"
        class="p-switch__field"
        type="checkbox"
        @change="onChange"
      />
      <p-loading class="p-switch__slider" size="sm" :loading="loading" />
    </label>
    <div v-if="$slots.append" class="p-switch__append">
      <slot name="append" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'
import { useField } from 'vee-validate'

const emit = defineEmits<{
  onChange: [value: any]
}>()

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
    validator: (val: string) => ['sm', 'md'].includes(val),
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const modelValue = toRef(props, 'modelValue')

const inputAttributes = {
  name: props.name,
  disabled: props.disabled,
  loading: props.loading,
}

const testAttribute = dataTest(props.name)

const { value } = useField(props.name, undefined, {
  initialValue: props.modelValue,
})
const onChange = () => emit('onChange', value)

watch(
  () => modelValue.value,
  () => (value.value = modelValue.value),
)
</script>
